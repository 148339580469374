const ALL_EFFECTS = [
  'crop',
  'rotate',
  'mirror',
  'flip',
  'blur',
  'sharp',
  'enhance',
  'grayscale',
  'invert',
]

export default ALL_EFFECTS
